<template>
  <div class="tabWrap">
    <contextmenu :itemList="menuItemList" :visible.sync="menuVisible" style="z-index: 9999;" @select="onMenuSelect"/>
    <a-tabs
      @contextmenu.native="e => onContextmenu(e)"
      v-if="multipage"
      :active-key="activePage"
      class="tab-layout-tabs"
      style="height:48px"
      :hide-add="true"
      type="editable-card"
      @change="changePage"
      @tabClick="tabCallBack"
      @edit="editPage">
      <a-tab-pane :id="page.path" :key="page.path" v-for="page in pageList" :closable="!(page.meta.title=='首页')">
        <span slot="tab" :pagekey="page.path">
          {{ page.meta.title }}
          <a-icon v-if="page.path == activePage" type="reload" @click="reloadPage" style="margin-left:5px;fontSize:10px;" />
        </span>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
  import Vue from 'vue'
  import Contextmenu from './Contextmenu'
  const indexKey = '/home'
  const CACHE_INCLUDED_ROUTES = 'CACHE_INCLUDED_ROUTES'

  export default {
    name: 'TabLayout',
    inject: ["reloadPage"],
    components: {
      Contextmenu
    },
    data() {
      return {
        pageList: [],
        linkList: [],
        activePage: '',
        menuVisible: false,
        menuItemList: [
          // { key: '4', icon: 'reload', text: '刷 新' },
          { key: '1', icon: 'arrow-left', text: '关闭左侧' },
          { key: '2', icon: 'arrow-right', text: '关闭右侧' },
          { key: '3', icon: 'close', text: '关闭其它' }
        ],
        reloadFlag:true
      }
    },
    provide(){
      return{
        closeCurrent:this.closeCurrent
      }
    },
    computed: {
      multipage() {
        return this.$store.state.system.multipage
      }
    },
    created() {
      if (this.$route.path != indexKey) {
        this.addIndexToFirst();
      }
      let currentRoute = Object.assign({}, this.$route);
      currentRoute.meta = Object.assign({}, currentRoute.meta);
      let storeKey = 'route:title:' + currentRoute.path;
      let routeTitle = this.$ls.get(storeKey);
      if (routeTitle) {
        currentRoute.meta.title = routeTitle;
      }
      this.pageList.push(currentRoute);
      this.linkList.push(currentRoute.path);
      this.activePage = currentRoute.path;
    },
    mounted() {
    },
    watch: {
      '$route': function(newRoute) {
        this.activePage = newRoute.path;
        if (!this.multipage) {
          this.linkList = [newRoute.path];
          this.pageList = [Object.assign({},newRoute)];
        } else if (this.linkList.indexOf(newRoute.path) < 0) {
          this.linkList.push(newRoute.path);
          this.pageList.push(Object.assign({},newRoute));
        } else if (this.linkList.indexOf(newRoute.path) >= 0) {
          let oldIndex = this.linkList.indexOf(newRoute.path);
          let oldPositionRoute = this.pageList[oldIndex];
          this.pageList.splice(oldIndex, 1, Object.assign({},newRoute,{meta:oldPositionRoute.meta}));
        }
      },
      'activePage': function(key) {
        let index = this.linkList.lastIndexOf(key);
        let waitRouter = this.pageList[index];
        if (waitRouter.path !== this.$route.path) {
          this.$router.push(Object.assign({}, waitRouter));
        }
      },
      'multipage': function(newVal) {
        if(this.reloadFlag){
          if (!newVal) {
            this.linkList = [this.$route.path];
            this.pageList = [this.$route];
          }
        }
      },
      device() {
        if (this.multipage && this.linkList.indexOf(indexKey) === -1) {
          this.addIndexToFirst();
        }
      },
    },
    methods: {
      triggerWindowResizeEvent() {
        let event = document.createEvent('HTMLEvents');
        event.initEvent('resize', true, true);
        event.eventType = 'message';
        window.dispatchEvent(event);
      },
      addIndexToFirst() {
        this.pageList.splice(0, 0, {
          name: 'home',
          path: indexKey,
          path: indexKey,
          meta: {
            icon: 'dashboard',
            title: '首页'
          }
        })
        this.linkList.splice(0, 0, indexKey);
      },
      changePage(key) {
        this.activePage = key;
      },
      tabCallBack() {
        this.$nextTick(() => {
         setTimeout(()=>{
           this.triggerWindowResizeEvent();
         },20);
        })
      },
      editPage(key, action) {
        this[action](key);
      },
      remove(key) {
        if (key == indexKey) {
          this.$message.warning(this.$t('首页不能关闭'));
          return
        }
        if (this.pageList.length === 1) {
          this.$message.warning(this.$t('这是最后一页，不能再关闭了'));
          return
        }
        let removeRoute = this.pageList.filter(item => item.path == key);
        this.pageList = this.pageList.filter(item => item.path !== key);
        let index = this.linkList.indexOf(key);
        this.linkList = this.linkList.filter(item => item !== key);
        index = index >= this.linkList.length ? this.linkList.length - 1 : index;
        this.activePage = this.linkList[index];
        let cacheRouterArray = Vue.ls.get(CACHE_INCLUDED_ROUTES) || [];
        if (removeRoute && removeRoute[0]) {
          let componentName = removeRoute[0].meta.componentName;
          if (cacheRouterArray.includes(componentName)) {
            cacheRouterArray.splice(cacheRouterArray.findIndex(item => item === componentName), 1)
            Vue.ls.set(CACHE_INCLUDED_ROUTES, cacheRouterArray);
          }
        }
      },
      onContextmenu(e) {
        const pagekey = this.getPageKey(e.target);
        if (pagekey !== null) {
          e.preventDefault();
          this.menuVisible = true;
        }
      },
      getPageKey(target, depth) {
        depth = depth || 0;
        if (depth > 2) {
          return null
        }
        let pageKey = target.getAttribute('pagekey');
        pageKey = pageKey || (target.previousElementSibling ? target.previousElementSibling.getAttribute('pagekey') : null);
        return pageKey || (target.firstElementChild ? this.getPageKey(target.firstElementChild, ++depth) : null);
      },
      onMenuSelect(key, target) {
        let pageKey = this.getPageKey(target)
        switch (key) {
          case '1':
            this.closeLeft(pageKey);
            break
          case '2':
            this.closeRight(pageKey);
            break
          case '3':
            this.closeOthers(pageKey);
            break
          case '4':
            this.reloadPage();
            break
          default:
            break
        }
      },
      closeCurrent(){
        this.remove(this.activePage);
      },
      closeOthers(pageKey) {
        let index = this.linkList.indexOf(pageKey);
        if (pageKey == indexKey || pageKey.indexOf('?ticke=')>=0) {
          this.linkList = this.linkList.slice(index, index + 1);
          this.pageList = this.pageList.slice(index, index + 1);
          this.activePage = this.linkList[0];
        } else {
          let indexContent = this.pageList.slice(0, 1)[0];
          this.linkList = this.linkList.slice(index, index + 1);
          this.pageList = this.pageList.slice(index, index + 1);
          this.linkList.unshift(indexContent.path);
          this.pageList.unshift(indexContent);
          this.activePage = this.linkList[1];
        }
      },
      closeLeft(pageKey) {
        if (pageKey == indexKey) {
          return
        }
        let tempList = [...this.pageList];
        let indexContent = tempList.slice(0, 1)[0];
        let index = this.linkList.indexOf(pageKey);
        this.linkList = this.linkList.slice(index);
        this.pageList = this.pageList.slice(index);
        this.linkList.unshift(indexContent.path);
        this.pageList.unshift(indexContent);
        if (this.linkList.indexOf(this.activePage) < 0) {
          this.activePage = this.linkList[0];
        }
      },
      closeRight(pageKey) {
        let index = this.linkList.indexOf(pageKey);
        this.linkList = this.linkList.slice(0, index + 1);
        this.pageList = this.pageList.slice(0, index + 1);
        if (this.linkList.indexOf(this.activePage < 0)) {
          this.activePage = this.linkList[this.linkList.length - 1];
        }
      },
    }
  }
</script>

<style lang="less">
.tabWrap{
  width: 100%;
  background: #fff;
  .ant-menu-item{
    color: #FFFFFF;
  }
  .page-transition-enter {
    opacity: 0;
  }

  .page-transition-leave-active {
    opacity: 0;
  }

  .page-transition-enter .page-transition-container,
  .page-transition-leave-active .page-transition-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }


  .tab-layout-tabs.ant-tabs {
    border-bottom: 0px solid #ccc;
    border-left: 0px solid #ccc;
    background-color: white;
    padding: 0 20px;

    .ant-tabs-bar {
      margin: 8px 0 0;
      border: none;
    }

  }

  .tab-layout-tabs.ant-tabs {

    &.ant-tabs-card .ant-tabs-tab {

      padding: 0 24px !important;
      // background-color: white !important;
      margin-right: 4px !important;

      .ant-tabs-close-x {
        width: 12px !important;
        height: 12px !important;
        opacity: 1 !important;
        cursor: pointer !important;
        font-size: 12px !important;
        margin: 0 !important;
        position: absolute;
        top: 36%;
        right: 6px;
      }
      .anticon-reload{
        color: rgb(0, 0, 0) !important;
        opacity: 0.45 !important;
        &:hover{
          opacity: 1 !important;
        }
      }

      &:hover .ant-tabs-close-x {
        opacity: 1 !important;
      }

    }

  }

  .tab-layout-tabs.ant-tabs.ant-tabs-card > .ant-tabs-bar {
    .ant-tabs-tab {
      border: 1px solid #e8e8e8 !important;
      border-bottom: 1px solid transparent !important;
    }
    .ant-tabs-tab-active {
      border-bottom: 1px solid transparent !important;
    }
  }
}
</style>
